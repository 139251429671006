import classNames from 'classnames';
import { ReactNode } from 'react';
import IconSolidDelete from '~/components/icons/streamline/solid/IconSolidDelete';

export interface CardProps {
  selected?: boolean;
  onClick?: () => void;
  onRemove?: () => void;
  children: ReactNode;
  className?: string;
  flush?: boolean;
  disabled?: boolean;
}

export default function Card(props: CardProps) {
  return (
    <div
      className={classNames('w-full rounded-theme transition duration-300', {
        'ring-theme-border ring-1': !props.selected,
        'ring-theme-highlight ring-3': props.selected,
        'bg-gray-100 cursor-not-allowed pointer-events-none select-none': props.disabled,
        'bg-theme-content text-theme-primary': !props.disabled,
        'select-none': !!props.onClick,
        'ring-2': !!props.onClick && !props.selected,
        'group-hover:ring-2 group-hover:ring-theme-highlight': !props.onClick && !props.disabled,
        'hover:ring-theme-highlight cursor-pointer': !!props.onClick && !props.disabled,
        'p-3': !props.flush,
        'pr-6 relative': props.onRemove,
      })}
      onClick={props.onClick}
    >
      <div
        className={classNames(props.className, {
          'opacity-50 grayscale': props.disabled,
        })}
      >
        {props.children}
      </div>
      {props.onRemove && (
        <div
          className="absolute flex items-center top-1/2 right-0 -translate-y-1/2 translate-x-1/2 text-red-600 bg-white rounded-full border border-theme-separator p-0.5"
          onClick={props.onRemove}
        >
          <span className="inline-block size-4">
            <IconSolidDelete />
          </span>
        </div>
      )}
    </div>
  );
}
